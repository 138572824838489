import {DeviceInfosInterface} from "../../app/models/device-infos.interface";

export namespace DeviceStateActions {

  export class SetDeviceInfos {
    static readonly type = '[AppState] setDeviceInfos';

    constructor(public deviceInfos: DeviceInfosInterface) {
    }
  }
}
