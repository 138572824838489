import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {DeviceStateModel} from "./device-state.model";
import {DeviceStateActions} from "./device-state.actions";
import {DeviceInfosInterface} from "../../app/models/device-infos.interface";

@State<DeviceStateModel>({
  name: 'DeviceState',
  defaults: {
    deviceInfos: null
  }
})
@Injectable()
export class DeviceState {

  @Selector()
  static deviceInfos(state: DeviceStateModel): DeviceInfosInterface | null {
    return state.deviceInfos;
  }

  @Action(DeviceStateActions.SetDeviceInfos)
  setDeviceInfos(ctx: StateContext<DeviceStateModel>, action: DeviceStateActions.SetDeviceInfos): DeviceInfosInterface {
    let newInfos: DeviceInfosInterface | null = null;
    const existingInfos = ctx.getState().deviceInfos;
    if (existingInfos) {
      newInfos = existingInfos;
      newInfos = {...action.deviceInfos, ...newInfos};
    } else {
      newInfos = action.deviceInfos
    }
    ctx.patchState(<DeviceStateModel>({
      deviceInfos: newInfos,
    }));

    console.log("new infos", existingInfos, newInfos);

    return newInfos
  }
}
